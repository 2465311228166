<template>
  <div class="global-header__flyout-sub">
    <FlyoutItemComponent
      v-for="(item, index) in model.subItems"
      :key="index"
      :depth="depth + 1"
      :hovered="hoveredStates[index]"
      :model="item"
      @hovered="
        (state: boolean) => {
          setHovered(index, state)
          emit('hovered', state)
        }
      "
    />
    <nav
      :class="{
        'global-header__flyout-sub-menu': true
      }"
      :style="{
        left: isOpen
          ? hovered && model.subItems.length > 0
            ? `${380 * depth}px`
            : `-${380 * depth}px`
          : '-380px'
      }"
      @mouseenter="onHoveredSelf(true)"
      @mouseleave="onHoveredSelf(false)"
    >
      <ul>
        <li
          v-for="(item, index) in model.subItems"
          :key="index"
          class="global-header__flyout-sub-menu__item"
          @mouseenter="setHovered(index, true)"
          @mouseleave="setHovered(index, false)"
        >
          <div
            :class="{
              'global-header__flyout-sub-menu__item__container': true,
              hover: hoveredStates[index]
            }"
          >
            <NuxtLink :to="item.url"> {{ item.title }}</NuxtLink>
            <IconCaretRight v-if="item.subItems && item.subItems.length > 0" />
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script lang="ts" setup>
import type { NavigationItem } from '~/types/NavigationItem'
import delay from 'lodash/delay'

const props = defineProps<{
  model: NavigationItem
  depth: number
  hovered: boolean
}>()

const { isOpen } = useMenuState()
const emit = defineEmits(['hovered'])
const onHoveredSelf = (state: boolean) => emit('hovered', state)
const hoveredStates = ref(props.model.subItems.map(() => false))
const hoverDelays = ref(props.model.subItems.map(() => -1))
const setHovered = (index: number, state: boolean) => {
  clearTimeout(hoverDelays.value[index])
  if (!state) {
    hoverDelays.value[index] = delay(() => (hoveredStates.value[index] = false), 100)
  } else {
    hoveredStates.value[index] = true
  }
}
</script>
