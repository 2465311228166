<template>
  <div class="global-header__flyout">
    <span
      :class="{
        'global-header__flyout-overlay': true,
        'is-open': isOpen
      }"
    ></span>
    <FlyoutItemComponent
      v-for="(item, index) in navigationItems"
      :key="index"
      :depth="1"
      :hovered="hoveredStates[index]"
      :model="item"
      @hovered="(state: boolean) => setHovered(index, state)"
    />
    <nav
      v-on-click-outside="onClickOutside as any"
      :class="{
        'global-header__flyout-menu': true,
        'is-open': isOpen
      }"
    >
      <ul>
        <li
          v-for="(item, index) in navigationItems"
          :key="index"
          class="global-header__flyout-menu__root-item"
          @mouseenter="setHovered(index, true)"
          @mouseleave="setHovered(index, false)"
        >
          <NuxtLink
            :class="{
              'global-header__flyout-menu__root-item__container': true,
              hover: hoveredStates[index]
            }"
            :to="item.url"
            @click="closeMenu()"
          >
            <span>
              {{ item.title }}
            </span>
            <IconCaretRight v-if="item.subItems && item.subItems.length > 0" />
          </NuxtLink>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script lang="ts" setup>
import { vOnClickOutside } from '@vueuse/components'
import delay from 'lodash/delay'
import type { NavigationItem } from '~/types/NavigationItem'
import { ApiResponse } from '~/types/ApiResponse.ts'

const { isOpen, closeMenu } = useMenuState()

const { $header } = useNuxtApp()
const headerResponse = $header as ApiResponse<NavigationItem[]>
const navigationItems: NavigationItem[] = headerResponse.data as NavigationItem[]

useNavigationItemStore().setItems(navigationItems)

const hoveredStates = ref(navigationItems.map(() => false))
const hoverDelays = ref(navigationItems.map(() => -1))
const setHovered = (index: number, state: boolean) => {
  clearTimeout(hoverDelays.value[index])
  if (!state) {
    hoverDelays.value[index] = delay(() => (hoveredStates.value[index] = false), 100)
  } else {
    hoveredStates.value[index] = true
  }
}

const hamburgerElement = ref<HTMLElement | null>()
const searchElement = ref<HTMLElement | null>()

const onClickOutside = [
  (e: any) => {
    if (!isOpen) return
    closeMenu()
  },
  { ignore: [hamburgerElement, searchElement] }
]

onMounted(() => {
  searchElement.value = document.getElementById('header-search-btn-wrapper')
  hamburgerElement.value = document.getElementById('header-hamburger-wrapper')
})
</script>
